import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import parse from "html-react-parser";
import AdvisorImg from "../../images/area-guide/advisors.jpg";

import LocRatingMap from '../map/loc-rating-map-area-guides';
import "../AreaGuideDetails/VideoBlock/VideoBlock.scss"
import { BRANCHES_PAGE_URL } from "../common/site/constants"
import PlayVideo from '../Play/CustomVideoFullImageClick';
import NoImage from "../../images/cubitt-no-image.png"

// markup
const VideoBlock = (props) => {


  // Negotiator Details get

  const data = useStaticQuery(graphql`
  query {
    allBranches(filter: {publish: {eq: true}, brand_id: {eq: "cw"}}) {
        totalCount
        edges {
        node {
            branch_id
            branch_name
            sales_dept {
              phone
              manager {
                image
                job_title
                name
              }
            }
            lettings_dept {
              phone
              manager {
                image
                job_title
                name
              }
            }
        }
        }
    }
  }
`);

  const propItems = data.allBranches.edges;

  var filteredTeamList = []
  filteredTeamList = propItems.filter(branch => {
    if (branch.node) {
      if (_.includes(branch.node.branch_name, props.Name)) {
        return branch;
      }
    }
  });
  // Negotiator Details get

  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat'] = props.Latitude
  mapItem['lng'] = props.Longtitude
  mapItem['id'] = props.id
  const mapMarkerImageShowFlag = 0;
  mapItems.push(mapItem);

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{5})(\d{6})$/);

    if (match) {
      return match[1] + ' ' + match[2]
    };

    return null
  };

  return (
    <React.Fragment>
      <div className="video-block-wrap area-guide-content components--AreaGuides--DetailsContent">
        <Container>
          <Row>
            <Col xl="8" lg="7">
              <div className="block-content">
                <div className="block-text">
                  {props.Add_Content.map((item, index) => (
                    <>
                      {item.Add_Content &&
                        <>{parse(item.Add_Content)}</>
                      }
                      {item.Image &&
                        <div className="video-image image-video">
                          {item.Video_URL ?
                            <PlayVideo url={item.Video_URL} >
                              <img src={item.Image.url} alt={item.Image.alternativeText} />
                            </PlayVideo> :
                            <img src={item.Image.url} alt={item.Image.alternativeText} />
                          }
                        </div>
                      }
                    </>
                  ))}
                  {/* {parse(props.Add_Static_Content)} */}

                  {props.Latitude && props.Longtitude &&
                    <div className="locrating-map">
                      <LocRatingMap data={mapItems} />
                    </div>
                  }

                </div>
              </div>
            </Col>
            <Col xl="4" lg="5" className="block-content block-text">
              <div className="guide-details-right-block main-banner">
                <div className="first">
                {filteredTeamList && filteredTeamList.length > 0 && filteredTeamList.slice(0, 1).map((person, index) => (
                    <React.Fragment>
                      {person.node.sales_dept && person.node.sales_dept.phone && person.node.sales_dept.manager?.name ?
                        <div className="advisor-info">
                          <div className="advisor-img">
                            {person.node.sales_dept.manager.image ?
                              <img src={person.node.sales_dept.manager.image} alt={person.node.sales_dept.manager.name} /> : <img src={NoImage} alt={person.node.sales_dept.manager.name} />}
                          </div>
                          <div className="advisor-contact">
                            <span className="advisor-name">{person.node.sales_dept.manager.name}</span>
                            <span className="advisor-designation">{person.node.sales_dept.manager.job_title}</span>
                            <a href={`tel:${person.node.sales_dept.phone}`} className="phone-number">
                              <i className="icon-phone"></i>
                              <span>{formatPhoneNumber(person.node.sales_dept.phone)}</span>
                            </a>
                          </div>
                        </div> : ''}
                      {person.node.lettings_dept && person.node.lettings_dept.phone && person.node.lettings_dept.manager?.name ?
                        <div className="advisor-info">
                          <div className="advisor-img">
                            {person.node.lettings_dept.manager.image ?
                              <img src={person.node.lettings_dept.manager.image} alt={person.node.lettings_dept.manager.name} /> : <img src={NoImage} alt={person.node.lettings_dept.manager.name} />}
                          </div>
                          <div className="advisor-contact">
                            <span className="advisor-name">{person.node.lettings_dept.manager.name}</span>
                            <span className="advisor-designation">{person.node.lettings_dept.manager.job_title}</span>
                            <a href={`tel:${person.node.lettings_dept.phone}`} className="phone-number">
                              <i className="icon-phone"></i>
                              <span>{formatPhoneNumber(person.node.lettings_dept.phone)}</span>
                            </a>
                          </div>
                        </div> : ''}
                    </React.Fragment>
                  ))}
                </div>

                <div className="info-text">
                  <p>We've got 40+ branches across Surrey, Sussex, Hampshire and the whole of the southeast – <Link to={BRANCHES_PAGE_URL.alias}>find your local one.</Link></p>
                </div>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default VideoBlock